export default {
  gray: '#C7C9C7',
  cream: '#E9E8E0',
  cream2: '#eeea97',
  red: '#7f0019',
  lightGray: '#E9E9E7',
  white: '#ffffff',
  blue: '#415463',
  green: '#2e963d',
  brown: '#9d202f',
  notBlack: '#2f2d30',
  black: '#000000'
}
